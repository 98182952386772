import React from "react"
import ContactBus from "../content/ContactBus"
import Mobile from "../content/Mobile"
import Banner from "../content/News/BannerNews"
import NewsCards from "../content/News/NewsCards"
import Footer from "../layout/Footer"
import SEO from "../SEO"
import "./cssberhasil"
import Header from "./HeaderSub"
import Navbar from "./NavbarSub"

import Helmet from "react-helmet"
import JSberhasil from "./jsberhasil"

const LayoutNewsAndEvents = ({ news }) => {
  return (
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074",
        }}
      />
      <SEO
        title="Pharmametrics Lab | News and Events"
        description="A Contract Research Organization (CRO) based in Indonesia providing full range of clinical trial and contract services to major companies in ASEAN"
      />
      <div id="perspective">
        <Mobile />
        <div id="wrapper" class="hasbg transparent">
          <div id="elementor-header" class="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              class="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div class="elementor-inner">
                <div class="elementor-section-wrap">
                  <Header />

                  <Navbar />
                </div>
              </div>
            </div>
          </div>
          <div id="elementor-sticky-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Navbar />
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" class="wide">
            <div class="inner">
              <div class="inner-wrapper">
                <div class="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    class="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <Banner />
                        <NewsCards news={news} />
                        <ContactBus />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
    </React.Fragment>
  )
}

export default LayoutNewsAndEvents
